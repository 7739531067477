import axios from "axios";

import { BASE_URL } from "@/config/ServiceConfig";

const NAME_APP_URL = `${BASE_URL}/name-meaning/v1`;
const { VUE_APP_ACCESS_TOKEN } = process.env;

export function nameApiUrl(path: string): string {
  return `${NAME_APP_URL}/${path}?token=${VUE_APP_ACCESS_TOKEN}`;
}

export const fetchMeaningOfName = (name: string): Promise<any> => {
  return axios.get(nameApiUrl(`${name}`));
};
