
import { defineComponent } from "vue";
import { fetchMeaningOfName } from "@/services/NameMeaningService";

export default defineComponent({
  props: {},
  data() {
    return { resData: {} };
  },
  methods: {
    loadContent() {
      const { name } = this?.$route?.params;
      if (!name) return;
      fetchMeaningOfName(`${name}`)
        .then(({ data }) => (this.resData = data.data))
        .catch((err) => console.log(err));
    },
  },
  computed: {
    userName() {
      return this.$route.params.name;
    },
  },

  beforeMount() {
    this.loadContent();
  },
});
